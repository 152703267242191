import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['string', 'slug']

  connect () {
    console.log('Sluggify controller connected')
    console.log(this.stringTarget.value)
    console.log(this.slugTarget.value)
  }

  updateSlug () {
    console.log(this.stringTarget.value)
    console.log(this.slugTarget.value)
    const stringValue = this.stringTarget.value
    const slugValue = stringValue.replace(/([a-z])([A-Z])/g, '$1-$2')
      .replace(/[\s_]+/g, '-')
      .toLowerCase()
    this.slugTarget.value = slugValue
  }
}
